import { FacebookEmbed } from "react-social-media-embed";
import StyledImage from "../components/Shared/StyledImage";
import StyledLink from "../components/Shared/StyledLink";

import logo from "../media/smeaton/logo.png";
import inspiringSquare from "../media/workedwith/wi-inspiring-square.png"
import etherliveLogo from "../media/workedwith/etherlive_logo_retina1.png"

import igLogo from "../media/links/instagram.png";
import lnLogo from "../media/links/linkedin.png";

function Card({children, className}: {children: any, className?: string}) {
    return (
        <div className={`flex flex-col items-center justify-center shadow-xl bg-tech-sec rounded-xl max-w-screen p-4 border border-slate-900 ${className}`} style={{willChange: "transform"}}>
            {children}
        </div>
    );
}

export default function HomePage(){
    return (
        <div className="flex flex-col items-center justify-center min-h-screen gap-y-[40vh] bg-tech-pri text-center p-2 pt-[20vh] pb-[60vh]">
            <Card className="w-[90vw]">
                <StyledImage srcHref={logo} classes="h-[20rem]"/>
                <h1 className="text-5xl font-bold text-tech-font-pri mb-5">
                    Smeaton Software
                </h1>
                <p className="text-2xl text-tech-font-pri mb-5">
                    Bespoke software development and consulting for your charity or business.
                </p>
            </Card>
            <Card>
                <h2 className="text-4xl font-bold text-tech-font-pri mb-5 border-b-2 border-tech-font-pri">
                    Our Expertise
                </h2>
                <p className="text-xl text-tech-font-pri">
                    We specialise in creating technical projects for businesses and charities.<br/>
                    No matter your use case, we are certain that we can create a solution that works for you.<br/>
                    Available for deployment on any of the below, we're sure we can get your service on a device you already own.<br/>
                </p>
                <div className="flex flex-row flex-wrap justify-center gap-x-[3rem] gap-y-[1rem] mt-4">
                    <StyledLink href="https://react.dev/" active={true} text="Web" onClick={()=>{}}/>
                    <StyledLink href="https://www.android.com/" active={true} text="Android" onClick={()=>{}}/>
                    <StyledLink href="https://www.apple.com/uk/ios/ios-15/" active={true} text="iOS" onClick={()=>{}}/>
                    <StyledLink href="https://www.microsoft.com/en-us/windows" active={true} text="Windows" onClick={()=>{}}/>
                    <StyledLink href="https://www.linux.org/" active={true} text="Linux" onClick={()=>{}}/>
                </div>
            </Card>
            <Card>
                <h2 className="text-4xl font-bold text-tech-font-pri mb-5 border-b-2 border-tech-font-pri">
                    Our Work Speaks For Us
                </h2>
                <p className="text-xl text-tech-font-pri mb-[4rem]">
                    We've empowered charities and businesses alike, transforming their visions into reality.<br/>
                    Partner with us, and we're certain you'll join our satisfied clientele.
                </p>
                <div className="flex flex-row gap-x-[8rem] items-end mb-4">
                    <div>
                        <StyledImage srcHref={inspiringSquare} classes="h-[20vh] mb-4"/>
                        <StyledLink href="https://somersetwi.co.uk/" active={true} text="Somerset WI" onClick={()=>{}}/>
                    </div>
                </div>
            </Card>
            <Card>
                <h2 className="text-4xl font-bold text-tech-font-pri mb-5 border-b-2 border-tech-font-pri">
                    Get In Touch
                </h2>
                <p className="text-xl text-tech-font-pri mb-5">
                    No matter your vision, we can help you bring it to life.<br/>
                    So send us an email <a href="mailto:oscar@smeatonsoftware.co.uk" className="underline">oscar@smeatonsoftware.co.uk</a><br/>
                    Or message us on our social media platforms.<br/>
                    <br/>
                    We will get back to you as soon as possible.
                </p>
                <div className="flex flex-row gap-x-[8rem] mt-[5vh]">
                    <div>
                        <StyledImage srcHref={lnLogo} classes="h-[15vh] mb-2"/>
                        <StyledLink href="https://www.linkedin.com/company/smeaton-software/" active={true} text="LinkedIn" onClick={()=>{}}/>
                    </div>
                    <div className="">
                        <StyledImage srcHref={igLogo} classes="h-[15vh] mb-2"/>
                        <StyledLink href="https://www.instagram.com/smeatonsoftware/" active={true} text="Instagram" onClick={()=>{}}/>
                    </div>
                </div>
            </Card>
        </div>
    );
}