import { useRoute } from "../../components/Page/Router";
import StyledButton from "../../components/Shared/StyledButton";
import StyledImage from "../../components/Shared/StyledImage";

import logo from "../../media/smeaton/logo.png";

export default function NotFoundPage() {
    const { route, changeRoute } = useRoute();

    return (
        <div className="min-h-screen flex items-center justify-center bg-tech-pri py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="flex flex-col justify-center">
                    <StyledImage srcHref={logo} classes="mx-auto"/>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-tech-font-pri">
                        404: Not Found!
                    </h2>
                    <p className="mt-2 text-center text-sm text-tech-font-pri">
                        Sorry, the page you are looking for does not exist.
                    </p>
                    <StyledButton onClick={()=>changeRoute('home')} label="Go Home" classes="mt-4 text-xl"/>
                </div>
            </div>
        </div>
    );
}