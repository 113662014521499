import { hrtime } from "process";

export interface IStyledLinkProps{
    text: string;
    onClick?: ()=>void;
    href?: string;
    active?: boolean;
    classes?: string;
}

export default function StyledLink(props: IStyledLinkProps){
    return <a href={props.href} target={props.href ? "_blank" : ""} onClick={props.onClick} className={`text-tech-font-pri font-semibold text-lg cursor-pointer hover:border-b-2 w-fit hover:border-wi-pri ${props.active && "border-b-2 border-tech-tri"} ${props.classes}`}>{props.text}</a>
}